@import 'css/lib/tailwind-custom/triangle';

.open-menu-button {
	&::after {
		width: calc(100% + 2px);
	}
	&::before {
		opacity: 1;
	}
	.hover-fill {
		fill: theme('colors.gray.DEFAULT');
	}

	outline: 1px dotted theme('colors.gray.300');
	outline-offset: 2px;
	box-shadow: 0px 0px 1px theme('colors.gray.400');
	&.noOutline {
		outline: none;
	}
	&.noShadow {
		box-shadow: none;
	}
}

.flyout-menu {
	@apply tw-relative tw-pl-1;

	&.menu-open {
		.flyout-menu-button {
			@extend .open-menu-button;
			&::before,
			&::after {
				// Need a higher value to prevent hover bleeding between other flyout menus.
				z-index: 110;
			}
		}
		.submenu {
			// Need a higher value to prevent hover bleeding between other flyout menus.
			z-index: 109;
		}
	}
}

.flyout-menu-button {
	@apply tw-flex tw-justify-center tw-items-center;
	width: 35px;
	height: 35px;
	margin-top: 9px;

	&[disabled] {
		@apply tw-opacity-50;
	}
	&:hover,
	&:focus {
		@extend .open-menu-button;
	}

	&::before {
		@apply tw-absolute tw-right-0 tw-mx-auto tw-my-0 tw-opacity-0;
		content: '';
		left: 4px;
		@include triangle(8px, theme('colors.gray.DEFAULT'), down);
		transition: opacity 0.2s ease-in-out;
		bottom: -11px;
		z-index: 108;
		pointer-events: none;
	}
	&:hover::before {
		opacity: 0;
	}

	&::after {
		@apply tw-h-0 tw-absolute tw-left-px tw-right-0 tw-mx-auto tw-my-0 tw-w-0;
		content: '';
		transition: width 0.2s ease-in-out;
		border-top: 3px solid theme('colors.gray.DEFAULT');
		bottom: -3px;
		z-index: 108;
		pointer-events: none;
	}

	.meatball {
		@apply tw-block tw-bg-gray-100 tw-items-center tw-justify-center;

		width: 5px;
		height: 5px;
		margin: 1.6px;
		border-radius: 5px;
	}
}

.submenu {
	@apply tw-grid tw-invisible tw-absolute tw-bg-gray-600 tw-text-gray-100 tw-drop-shadow;

	padding: 24px 20px 20px 25px;
	top: 100%;
	width: 200px;
	z-index: 100;
	border-top: 3px solid theme('colors.gray.400');
	grid-template-columns: auto;

	&.left {
		right: -1em;
	}

	&.center {
		right: -83px;
	}

	&.right {
		left: -1em;
	}

	a, button {
		@apply tw-text-gray-100 tw-no-underline;

		&:hover {
			@apply tw-text-blue;
		}
	}

	ul {
		margin-bottom: 0;
	}

	li {
		margin-bottom: 19px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.menuContainer {
	@apply tw-divide-y tw-divide-gray-400;
}

.menuGroup {
	@apply tw-space-y-3;
}

.menuGroupContainer {
	.menuGroup {
		@apply tw-py-4;
	}
	// Order is very important here for these pseudo classes.
	// `only-child` needs to win in the end since it matches the previous rules.
	&:first-child {
		.menuGroup {
			@apply tw-pt-0 tw-pb-4;
		}
	}
	&:last-child {
		.menuGroup {
			@apply tw-pt-4 tw-pb-0;
		}
	}
	&:only-child {
		.menuGroup {
			@apply tw-py-0;
		}
	}
}
