.MagicPaginationNav {
	@apply tw-whitespace-nowrap tw-select-none;
}
.MagicPaginationNav-button {
	@apply tw-cursor-pointer tw-text-center tw-border tw-border-solid tw-border-gray-400;
	margin-left: -1px;
	width: 42px;
	height: 42px;
	line-height: 42px;
}
