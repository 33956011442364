@media screen {
	.customer-search-results {
		margin: -5px 0 0;
		word-wrap: break-word;
		.customer-search-result-list {
			min-height: 400px;
			&.customer-search-result-link-list {
				.p-email {
					font-weight: bold;
				}
				.link-and-continue-button {
					margin-top: 32px
				}
			}
			.p-email {
				word-break: break-word;
				word-wrap: break-word;
			}
		}
		.customer-search-result-item {
			border-bottom: 1px solid theme('colors.gray.400');
			padding: 0 0 15px;
			margin: 0 0 15px;
			&:only-child {
				border: none;
			}
			> button {
				cursor: pointer;
				border: none;
				background: none;
				width: 100%;
				text-align: left;
				padding: 0;
			}
			&.partial-warning-message {
				@apply tw-flex;
				.warning-icon {
					margin: 3px 2% 0 0;
					img {
						width: 50px;
					}
				}
				.warning-symbol {
					flex: 0 0 28px;

					&::before {
						@apply tw-rounded-2xl tw-block tw-text-center tw-text-red tw-font-semibold;
						content: '!';
						width: 21px;
						height: 22px;
						border: solid 1px theme('colors.red.DEFAULT');
					}
				}
				.warning-message-content {
					@apply tw-text-red;
				}
			}
		}

		.customer-search-result {
			@apply tw-flex;
		}

		.link-status {
			width: 15px;
			margin: 0 2% 0 0;
		}

		.link-summary {
			margin: 0 0 10px;
			dt {
				float: left;
				margin: 0 5px 0 0;
			}
		}

		.customer-group {
			display: inline-block;
			width: 18px;
			margin: 0 0 0 8px;
		}

		.customer-search-col {
			width: 48%;
			&:not(:last-child) {
				margin-right: 4%;
			}
			// This rule needs to be declared last
			&:first-child {
				margin-left: calc(#{2%} + 15px);
			}

			.phones, .address {
				margin-bottom: 1rem;
				line-height: 1.4;
			}
		}
		.customer-result-link-success {
			.customer-search-col:first-child {
				margin-left: 0;
			}
			.p-email {
				margin-bottom: 1rem;
			}
		}
		.customer-search-heading {
			padding: 2px 8px;
			margin: 40px 0 20px;
		}
		.is-read-only .p-name {
			color: inherit;
			text-decoration: none;
			cursor: auto;
		}
		.p-name {
			@apply tw-text-blue tw-font-bold tw-p-0 tw-text-left tw-underline;
			border: none;
			background: none;
			word-break: break-word;
			word-wrap: break-word;
		}
		.activity-date,
		.p-street-address,
		.tax-exempt,
		.flags {
			padding-top: 8px;
		}
		.activity-date,
		.flags {
			font-size: 13px;
		}
		.flags span+span::before {
			padding: 0 6px;
			content: '|';
		}
		.tax-exempt {
			@apply tw-text-orange tw-italic;
		}
		.error-message {
			margin: 0 0 400px;
			button {
				@apply tw-text-blue tw-underline tw-inline tw-m-0 tw-p-0 tw-cursor-pointer;
				border: none;
				background: none;
				font-family: inherit;
			}
		}
		.customer-search-footer {
			margin: 0 0 10px;
		}
		.customer-search-footer-control-title {
			@apply tw-p-0 tw-text-gray-100;
			margin: 0 0 10px;
		}
	}
}
