.pre-footer-item {
	@apply tw-flex tw-flex-col tw-items-center tw-px-4 tw-my-5;

	@screen md {
		@apply tw-px-8;
	}
}

.pre-footer-item-image {
	@apply tw-mb-4;
}

.pre-footer-item-title {
	@apply tw-heading-5 tw-mb-1;
}

.pre-footer-item-copy {
	@apply tw-mb-2 tw-text-center;

	@screen md {
		max-width: 280px;
	}

	@screen lg {
		max-width: 240px;
	}
}
