.entire-header-container {
	width: 100vw;
	max-width: 100%;
	.header-container {
		max-width: 1600px;
		margin: auto;
		padding-left: 9px;
		padding-right: 9px;
	}
	.dividers {
		@apply tw-border-b tw-border-t tw-border-solid tw-border-gray-400;

		@media print {
			@apply tw-border-gray-100;
		}
	}
}

:global(.page) {
	&.full-screen-page {
		@apply tw-mt-0;

	}

	&.checkout-page .page-body {
		padding: 0 5px;
	}

	.page-body {
		width: 1200px;
		max-width: calc(100vw - 18px);
		margin: auto;
		@screen md {
			padding-top: 9px;
			padding-bottom: 9px;
		}
		&.page-body-1600 {
			@screen md {
				width: 1600px;
				padding-left: 21px;
				padding-right: 21px;
			}
		}
		&.page-body-fullWidth {
			@screen md {
				width: 100vw;
				max-width: 100%;
			}
		}
	}
}

.mobile-menu-open {
	body {
		@apply tw-overflow-hidden;
	}

	.page {
		overflow: hidden;
		position: relative;
		height: 100%;
	}

	.main-content {
		max-width: calc(100vw - 18px);
		position: fixed;
		zoom: 1;
		width: 100%;
	}

	section[class*="footer-container"] {
		position: fixed;
		z-index: -1;
		top: 100%;
		width: 100%;
	}
}
