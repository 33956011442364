$navy100: theme('colors.navy.100');
$white: theme('colors.white.DEFAULT');
$toolbarHeight: 75px;

.toolbarWrapper {
	@apply tw-top-0 print:tw-hidden;
	z-index: calc(var(--toolbar-zi-base) + 10);
}
:global(.is-engage-site) .toolbarWrapper {
	@apply tw-sticky;
}
.engageToolbar {
	@apply tw-relative tw-bg-navy-100 tw-flex tw-items-center tw-justify-between tw-gap-0 eng:tw-gap-5;
	height: $toolbarHeight;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
	z-index: var(--toolbar-zi-base);
	:global(.location-field-group-profileLocationForm) {
		@apply tw-m-0;
	}
	&.simple {
		@apply tw-font-bold tw-text-white tw-justify-center tw-p-4 tw-uppercase;
		height: 42px;
	}


	@media print {
		@apply tw-hidden;
	}
}
.menuItem {
	@apply tw-h-full tw-outline-white tw-shadow-none tw-text-navy-100 tw-select-none;
	width: 67px;
	outline-color: $white !important;
	box-shadow: none !important;
	outline-offset: -3.5px !important;
	svg {
		@apply tw-w-full;
		path,
		circle {
			@apply tw-fill-transparent tw-stroke-white;
		}
	}
	path[data-fill] {
		@apply tw-fill-navy-100;
	}
	&.isSubmenuOpen {
		@apply tw-bg-white tw-rounded-md;
		border: 3px solid $navy100;
		&:focus {
			outline: none !important;
		}
	}
}
.flyoutMenuItem {
	@apply tw-h-full tw-outline-white tw-shadow-none tw-text-navy-100;
	padding-left: 0 !important;
	svg {
		@apply tw-w-full;
		path,
		circle {
			@apply tw-fill-transparent tw-stroke-white;
		}
	}
	path[data-fill] {
		@apply tw-fill-navy-100;
	}
	.submenu {
		top: 100% !important;
	}
	&:global(.menu-open) .flyoutMenuButton::after {
		width: calc(100% - 3.5px) !important;
	}
}
.flyoutMenuButton {
	width: 67px !important;
	height: 100% !important;
	margin: 0 !important;
	box-shadow: none !important;
	&::before {
		display: none !important;
	}
	&::after {
		border-color: $white !important;
		bottom: 0 !important;
	}
	&:focus {
		outline: 1px dotted $white !important;
		outline-offset: -3.5px !important;
	}
	&:focus::after,
	&:hover::after {
		width: calc(100% - 3.5px) !important;
		left: 0 !important;
	}
}
// e.g. Profile menu
.circularFlyoutMenuButton {
	margin: 0 !important;
	box-shadow: none !important;
	border-radius: 100% !important;
	&::before {
		display: none !important;
	}
	&::after {
		border-color: $white !important;
		bottom: -20px !important;
	}
	&:focus {
		outline: 1px dotted $white !important;
	}
	&:focus,
	&:hover {
		@apply tw-bg-white;
		.circularFlyoutMenuText {
			@apply tw-text-gray;
		}
	}
}
.activeEffectFill {
	&:focus,
	&:hover,
	&.isSubmenuOpen {
		path {
			@apply tw-fill-white;
		}
		circle {
			@apply tw-fill-navy-100;
		}
	}
}
.activeEffectStroke {
	&:focus,
	&:hover,
	&.isSubmenuOpen {
		path {
			@apply tw-stroke-navy-100;
		}
	}
}
// This hijacks the same class from FlyoutMenu component.
.submenu {
	@apply tw-invisible tw-absolute tw-bg-gray-600 tw-drop-shadow tw-text-gray tw-px-6 tw-py-4;
	top: calc(100% + 20px) !important;
	z-index: 100;
	max-width: 300px;
	&[data-submenu-for-button-id^="meatball-menu-profile-button"] {
		max-width: none;
	}
	&[data-submenu-for-button-id^="meatball-menu-delivery-summary-button"] {
		&,
		&.right {
			left: 50%;
			transform: translateX(-50%);
			width: max-content;
			max-width: 340px;
		}
	}
	border-top: 3px solid theme('colors.gray.400');

	&.left {
		right: -13px;
	}

	&.center {
		right: -83px;
	}

	&.right {
		left: -20px;
	}
}
.divider {
	&::before,
	&::after {
		@apply tw-bg-white tw-flex-shrink-0 tw-bg-opacity-50 tw-w-0 tw-my-[1.167rem];
	}
	&.dividerLeft::before {
		@apply tw-w-px;
	}
	&.dividerRight::after {
		@apply tw-w-px;
	}
}
.newGuest {
	@apply tw-bg-transparent tw-font-bold tw-leading-none tw-border tw-border-white tw-text-white;
	@apply enabled:hover:tw-bg-white enabled:hover:tw-text-gray focus:tw-bg-white focus:tw-text-gray;
	@apply disabled:tw-border-white disabled:tw-opacity-50 tw-select-none;
	padding: 5px;
	&:focus {
		outline-color: $white !important;
	}
}
@screen eng {
	.newGuest {
		padding: 5px 15px;
	}
	.divider {
		&::before,
		&::after {
			@apply tw-hidden;
		}
	}
	.submenu[data-submenu-for-button-id^="meatball-menu-delivery-summary-button"] {
		&,
		&.right {
			left: -20px;
			transform: none;
		}
	}
}
