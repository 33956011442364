@media screen {
	.most-recent-customers-modal {
		.recent-customers-accordion {
			@apply tw-grow tw-w-full;
		}
		.most-recent-customers-container {
			@apply tw-flex tw-flex-col tw-grow tw-items-center;
		}
		.legacy-modal {
			@apply tw-flex tw-flex-col;
			min-height: 981px;
		}
		.modal-content {
			@apply tw-flex tw-grow;
		}
		.recent-more-accordion-item {
			border-bottom: 1px solid theme('colors.gray.400');
			padding: 16px 8px;
		}
		.recent-more-row {
			@apply tw-flex tw-flex-row tw-justify-between tw-items-center;
		}
		.recent-more-cart-images {
			@apply tw-flex tw-items-center tw-mt-4 tw-mb-0 tw-mx-0;
			min-height: 100px;
			img {
				display: block;
				max-width: 110px;
				max-height: 80px;
				& + img {
					margin: 0 0 0 8px;
				}
			}
		}
		.recent-more-entry {
			text-align: left;
			padding-left: 10px;
			&:first-child {
				padding-left: 0;
			}
		}
		.recent-more-name {
			@apply tw-font-bold;
			flex-basis: 220px;
			word-wrap: break-word;
			word-break: break-all;
		}
		.recent-more-last-activity-date {
			flex-basis: 175px;
		}
		.recent-more-control {
			@apply tw-text-blue tw-no-underline;
			border: 1px solid theme('colors.blue.DEFAULT');
			width: 16px;
			height: 16px;
			&:before {
				width: 14px;
				height: 14px;
				display: block;
				line-height: 14px;
				content: '+';
			}
			&.is-open:before {
				content: '−'
			}
		}
		.show-more-button-container {
			@apply tw-flex tw-flex-col tw-items-center;
			margin: 20px 0 5px;
		}
	}
	.most-recent-customers-modal.most-recent-store-customers-modal {
		.recent-more-employee-name {
			flex-basis: 42%;
		}
		.recent-more-last-activity-date {
			flex-basis: 33%;
		}
	}
	.most-recent-customers-modal.open-cart-customers-modal {
		.recent-more-last-activity-date {
			flex-basis: 54%;
		}
	}
}
