$devBeamHeight: 42px;
$engageToolbarHeight: 75px;
$combinedHeight: $devBeamHeight + $engageToolbarHeight;

@media screen {
	// Contents
	.side-menu-contents {
		//padding: 5px;
		-webkit-overflow-scrolling: touch;
		height: 100%;
		.section {
			padding: 0 0 20px;
			margin-bottom: 25px;
			border-bottom: 1px solid theme('colors.gray.400');
			&:last-child {
				border-bottom: none;
			}
			h3, h3 > label {
				margin: 5px 0 10px;
			}
			.more-tools {
				@apply tw-flex tw-flex-col;
				a {
					font-size: 16px;
					margin-bottom: 15px;
					color: inherit;
				}
			}
		}
		&.side-menu-contents-quicklinks,
		&.side-menu-contents-shop {
			li {
				font-size: 16px;
				a {
					font-size: inherit;
					display: inline-block;
					padding: 10px 0;
					line-height: 1;
					&[href$='.pdf'] {
						background: url(//rnb.scene7.com/is/image/roomandboard/icon_pdf) no-repeat 100% 50%;
						padding-right: 30px;
					}
				}
			}
		}
	}
	// Menu
	.oe-side-menu {
		@apply tw-fixed tw-left-0 tw-flex tw-flex-col tw-bg-gray-600 tw-overflow-x-hidden tw-overflow-y-auto;
		ol,
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
		}
		width: 340px;
		z-index: var(--toolbar-zi-menus);
		height: calc(100vh - #{$engageToolbarHeight});
		top: $engageToolbarHeight;
		border-top: 3px solid theme('colors.gray.400');
		//border-bottom: 1px solid #ff00ff;
		transform: translateX(-100%);
		transition: transform var(--toolbar-anim-speed) ease-out 150ms;
		:global(.dev-beam) ~ & {
			top: $combinedHeight;
			height: calc(100vh - #{$combinedHeight});
		}
		.topRow {
			@apply tw-flex tw-flex-row tw-justify-end;
			a {
				font-size: 12px;
			}
		}
		.form-layout > * {
			@apply tw-flex tw-flex-col tw-justify-between tw-items-end;
		}

		:global .formz {
			@apply tw-flex tw-flex-col;
		}
		:global .field-group {
			input,
			select {
				@apply tw-box-border;
				margin-bottom: 20px;
				margin: 0;
				width: 100%;
			}
			label {
				width: 100%;
				padding: 0;
				margin: 0 0 5px;
			}
			.validation {
				@apply tw-text-red;
				width: 100%;
				margin: 6px 0 0;
			}
		}
		input[type='submit'] {
			@apply tw-self-end;
			margin: 0 0 7px;
		}
	}
	.oe-menu-enter-done {
		transform: translateX(0);
	}
	.oe-menu-enter.oe-menu-enter-active {
		transform: translateX(0);
		z-index: var(--toolbar-zi-menus);
	}
	.oe-menu-exit {
		transform: translateX(-100%);
	}
	.oe-menu-exit.oe-menu-exit-active {
		transform: translateX(-100%);
		transition: transform var(--toolbar-anim-speed) ease-out;
		z-index: var(--toolbar-zi-menus);
	}
	// Overlay
	.menu-overlay {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		z-index: var(--toolbar-zi-menus);
		background-color: rgba(0, 0, 0, 0.5);
		//background: red;
	}
	.oe-menu-overlay-enter {
		opacity: 0.01;
		transition: opacity var(--toolbar-anim-speed) ease-in;
	}
	.oe-menu-overlay-enter.oe-menu-overlay-enter-active,
	.oe-menu-overlay-enter-done {
		opacity: 1;
		transition: opacity var(--toolbar-anim-speed) ease-in;
	}
	.oe-menu-overlay-leave {
		opacity: 1;
	}
	.oe-menu-overlay-leave.oe-menu-overlay-leave-active {
		opacity: 0.01;
		transition: opacity var(--toolbar-anim-speed) ease-out 150ms;
	}
	.sideMenuInside {
		padding: 1.367rem 2.734rem 1.367rem 1.367rem;
	}
}

@media (prefers-reduced-motion) {
	.oe-side-menu,
	.oe-menu-exit.oe-menu-exit-active,
	.oe-menu-overlay-enter-done,
	.oe-menu-overlay-leave.oe-menu-overlay-leave-active {
		transition: none;
	}
}
