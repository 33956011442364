.pre-footer {
	@apply tw-py-4;

	@screen md {
		@apply tw-py-6;
	}
}

.pre-footer-items {
	@apply tw-grid tw-grid-cols-1 tw-m-0;

	@screen md {
		@apply tw-grid-cols-2;
	}

	@screen lg {
		@apply tw-divide-x tw-divide-gray-400 tw-grid-cols-4;
	}
}

.pre-footer-item {
	@apply tw-flex tw-flex-col tw-items-center tw-px-8 tw-my-4;
}